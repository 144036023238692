* {
  box-sizing: border-box !important;
  /* outline: 1px solid black; */
}

body {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}

ul li::before {
  content: "▸";
  color: #AD8B73;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
  padding: 15px;
  padding-bottom: 0px;
}
.intoPageLink::before {
  content: "▸";
  color: white;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
  padding: 15px;
  padding-bottom: 0px;
}
/* .textAlignLeft li.intoPageLink
    {
        color:white ;
    }
.textAlignLeft li.intoPageLink2
    {
        color:red ;
    } */

@media only screen and (min-width: 960px) {
  ul li::before {
    padding-bottom: 15px;
  }
}
/* ul li {
  list-style: "▸ ";
  word-break: break-word;
} */